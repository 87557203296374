<template>
  <div
    class="tabs is-toggle is-small"
    :class="{ 'is-right': $mq !== 'widescreen' }"
  >
    <ul>
      <li
        @click="goTo('companion')"
        v-tippy="{ placement: 'top' }"
        title="View vehicle in Companion"
        data-test-id="autocheck-tabs-companion"
      >
        <a>
          <span class="icon is-hidden-desktop is-small is-marginless">
            <img :src="require('core/img/icons/percayso-logo.svg')" />
          </span>
          <span class="is-hidden-touch is-size-6" :class="textWeight">
            Companion
          </span>
        </a>
      </li>
      <li
        :class="{ 'is-active': content === 'timeline' }"
        @click="goToTimeline()"
        data-test-id="autocheck-tabs-timeline"
      >
        <a>
          <span class="icon is-hidden-desktop is-small is-marginless">
            <i class="fas fa-history" />
          </span>
          <span class="is-hidden-touch is-size-6" :class="textWeight">
            Timeline
          </span>
        </a>
      </li>
      <li
        :class="{ 'is-active': content === 'autocheck' }"
        @click="goTo('autocheck')"
        data-test-id="autocheck-tabs-report"
      >
        <a>
          <span class="icon is-hidden-desktop is-small is-marginless">
            <i class="fas fa-check" />
          </span>
          <span class="is-hidden-touch is-size-6" :class="textWeight">
            AutoCheck Report</span
          >
        </a>
      </li>
      <li
        :class="{ 'is-active': content === 'additional-data' }"
        @click="goTo('additional-data')"
        data-test-id="autocheck-tabs-data"
      >
        <a>
          <span class="icon is-hidden-desktop is-small is-marginless">
            <i class="fas fa-line-columns" />
          </span>
          <span class="is-hidden-touch is-size-6" :class="textWeight">
            Additional data</span
          >
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getTimeline } from 'modules/vehicle-profile/services'
export default {
  name: 'AutoCheckTabs',
  props: {
    content: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapMutations('autocheck', ['setTimeline']),
    goTo(tab) {
      this.$emit('goTo', tab)
    },
    async goToTimeline() {
      if (this.timeline === null) {
        try {
          const timeline = await getTimeline(this.vin)
          this.setTimeline(timeline)
        } catch (error) {
          this.$notify('Failed to fetch timeline')
          return
        }
      }

      this.$emit('goTo', 'timeline')
    }
  },
  computed: {
    ...mapGetters('autocheck', ['timeline', 'vin']),
    textWeight() {
      return this.$experian
        ? 'has-text-weight-bold'
        : 'has-text-weight-semibold'
    }
  }
}
</script>
